.sectionWrap {
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 2rem
}

.section {
  color: inherit;
}

.sectionWrap>div:not(:last-child) {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.picker {
  margin: 2rem 0
}