@import "../variables.scss";

.svc-rating-question-controls {
  position: absolute;
}

.svc-question__content--selected-no-border {
  .svc-rating-question-controls.svc-item-value-controls {
    display: flex;
  }
}

.svc-question__content--selected {
  .svc-rating-question-controls.svc-item-value-controls {
    display: flex;
  }
}

.svc-question__content {
  .sd-rating {
    margin-inline-start: calcSize(9); // margin-left
    width: calc(100% - 9 * #{$base-unit});
  }
  .svc-rating-question-controls {
    display: flex;
    width: calcSize(8);
    min-width: auto;
    justify-content: flex-start;
  }
  .sd-rating__item--fixed-size:focus-within {
    width: unset;
    padding: calcSize(0.5) calcSize(2.5);
  }
}
