.wrap {
  border-radius: 5;
  margin: 1rem;
}

.error {
  background-color: 'red';
}

.error span {
  background-color: 'red';
}

.backButton {
  border-color: #cccccc;
  border-width: 2px;
  margin-right: 16px;
}

.cardWrap {
  padding: 2rem;
  margin-top: 24px;
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
}

.sectionCardWrap {
  margin-top: 24px;
}
