.wrap {
  border-radius: 1rem;
  padding: 2rem;
  background-color: #fff;
  box-shadow:
    0px 12px 24px -4px rgba(204, 204, 204, 0.2),
    0px 0px 2px 0px rgba(204, 204, 204, 0.4);
}

.titleWrap {
  display: flex;
  justify-content: space-between;
}

.dot {
  margin-top: -0.2rem;
  margin-right: 0.5rem;
}

.nameCount {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  padding-left: 0.2rem;
  color: #999;
}

.subName {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.titleWrap span {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.accordionWrap {
  margin-top: 1.5rem;
}

.accordion {
  margin: 0 !important;
  box-shadow: none !important;
}

.headerAccordion {
  margin: 0 !important;
  box-shadow: none !important;
}

.headerAccordion::before {
  content: none !important;
}

.accordionSummary,
.accordionDetails {
  padding: 0;
}

.accordionDetails {
  /* border-top: 1px solid #f3f3f3 !important; */
  border-bottom: 1px solid #f1f1f1 !important;
}

.headerItem p,
.headerItemLast p {
  color: #999999;
  font-weight: 600;
}

.headerItemLast,
.parentItemLast {
  text-align: right;
}

.parentItem p,
.parentItemLast p {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #444;
}

.childItemLast {
  text-align: right;
  padding-right: 1.5rem;
}

.childItem p,
.childItemLast p {
  color: #999;
  padding-bottom: 0.6rem;
}

/* .rightAlign p {
  text-align: right;
} */

/* .grid p:last-child {
  text-align: right;
} */
.parentheses::first-line {
  color: red;
}

.icon {
  color: #bbb;
  margin-left: 0.5rem;
  font-size: 12px;
}

.pointer {
  cursor: pointer;
}
