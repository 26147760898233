.wrap {
  margin-top: 24px;
}

.wrapChart {
  padding: 2rem;
}

.overview {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 2rem;
  color: #999999;
  margin-right: 2rem;
}

.num {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  color: #333333;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.detailBox {
  width: auto !important;
  margin-top: 12px !important;
}
