#walk-in-form {
  --primary: #e8192c;
  height: calc(100vh - var(--header-height) - var(--footer-height) - 2 * var(--content-padding));
}

#walk-in-form .svc-top-bar {
  --primary: #007bff;
}

#walk-in-form .svc-tab-designer .sd-container-modern .sd-container-modern__title {
  background-color: #e8192c;
}

#walk-in-form .sd-root-modern:not(.svc-tab-designer) .sd-container-modern__title {
  background-color: #e8192c;
}

#walk-in-form .sd-header__text h3 {
  color: #fff;
}

#walk-in-form .sd-header__text .sd-description {
  color: #fff;
}

#walk-in-form .sd-btn--action {
  display: block;
  color: var(--primary, #19b394);
  background-color: #fff;
  &:hover {
    background-color: #fff;
  }
}

#walk-in-form .svd-test-results {
  display: none;
}
