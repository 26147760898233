// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import './scss/layout.scss';

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.cursor-default {
  cursor: default !important;
}

.tree-select .tree-select-option {
  margin-left: 0 !important;
}
body > iframe {
  pointer-events: none;
}
#webpack-dev-server-client-overlay {
  // allow click to close webpack-error-overlay iframe
  pointer-events: visible;
}
