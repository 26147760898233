.wrap {
  margin-top: 24px;
}

.wrap span {
  color: #333;
  font-size: 0.9rem;
}

.error {
  background-color: 'red';
}

.wrapChart {
  padding: 2rem;
}

.workingLeads {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 2rem;
  color: #999999;
  margin-right: 2rem;
}

.num {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  color: #333333;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.campaignName {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  margin-bottom: 8px;
}

.campaignInfo {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #999999;
  margin-bottom: 1rem;
}

.sectionBox {
  padding-bottom: 8px;
  border-top: 1px solid #f0f0f0;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.senctionTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

.sectionValue {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-align: right;
}

.sectionDesc {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #999999;
}

.sectionDescValue {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
}
