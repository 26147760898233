.wrap {
  border-radius: 5;
  margin: 1rem
}

.wrap span {
  color: #fff;
  font-size: 1rem;
}

.icon {
  color: #bbb;
  margin-left: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
}