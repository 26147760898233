.wrap {
  margin-top: 1rem
}

.wrapChart {
  padding: 2rem;
  margin: 1rem 0
}

.workingLeads {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 2rem;
}

.num {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
}