html {
  font-family: sans-serif; // 1
  text-size-adjust: 100%; // 2
  -ms-text-size-adjust: 100%; // 2
  -webkit-text-size-adjust: 100%; // 2
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Body and HTML
html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 13px !important;
  font-weight: 400;

  -ms-text-size-adjust: 100%; // 2
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 960px) {
  body {
    font-size: 12px !important;
  }
}

// Body
body {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  color: #3f4254;
  margin: 0;
}

// Angular Rounter Element
router-outlet {
  display: none;
}

// General Links
a:hover,
a:active,
a:focus {
  text-decoration: none !important;
}

// Canvas
canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

// React root container
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

// Global link cursor
a {
  cursor: pointer;
}

// quick-actions-panel
.MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.5) !important;
  left: 0;
}

.table-footer-css {
  display: block !important;
  width: 100%;
  height: 54px;
  background: white;
  border-bottom-left-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;

  .MuiTableRow-footer {
    float: right;
  }
}

.pro-table-row:not(.Mui-selected):hover > .sticky-cell {
  background-color: #f5f5f5;
}

.Mui-selected > .sticky-cell {
  background-color: #fef6f7;
}

.pro-table-row.Mui-selected {
  background-color: #fef6f7 !important;
}

#Popover-Selector > .MuiBackdrop-root {
  background: none !important;
}

#webpack-dev-server-client-overlay {
  // allow click to close webpack-error-overlay iframe
  pointer-events: visible;
}
