.wrapChart {
  padding: 2rem;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  border-top: #f0f0f0 1px solid;
  cursor: pointer;
}

.titleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.title {
  color: #999999;
  line-height: 20px;
  padding-bottom: 24px;
}

.detailWrap {
  margin-top: 24px;
}

.tag {
  width: 44px;
  height: 24px;
  border-radius: 12px;
  background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
