.wrap {
  border-radius: 5;
  margin: 1rem
}

.display {
  display: '';
}

.hide {
  display: none;
}