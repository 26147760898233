.spg-input-container {
  display: flex;
  justify-content: space-between;
  cursor: default;
  padding: calcSize(0.5);
  align-items: center;
  gap: calcSize(0.5);
}
.spg-color-editor__color-input {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  left: calc(50%);
  top: calc(100%);
  transform: translate(-50%, -50%);
}
.spg-color-editor__color-swatch {
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  width: calcSize(4.5);
  height: calcSize(3);
  margin-left: calcSize(1.5);
}
