.connectorActive span {
  background-color: #38527E;
}

.content {
  padding-top: 0 !important;
  overflow-wrap: anywhere
}

.labelActive {
  color: #333;
  font-weight: 500;
}

.labelInactive {
  color: #999;
  font-weight: 500;
}

.reason {
  font-size: 0.9rem;
  font-weight: 400
}

.wrap {
  width: 400px;
}