.wrap {
  border-radius: 5;
  margin: 1rem
}

.leadBtn {
  border-radius: 2rem !important;
  margin-right: 1rem !important;
}

.disabled {
  pointer-events: none;
}