.wrap {
  /* max-width: 160px; */
  word-break: break-all;
  gap: 4px;
  border: 0px 1px 0px 0px;
  padding: 0 1rem;
}

.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #999;
}

.value {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
  text-align: left;
  color: #333;
  padding-top: 0.5rem;
}

.error {
  background-color: 'red';
}
