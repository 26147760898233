.wrap {
  margin-top: 24px;
}

.wrap span {
  color: #333;
  font-size: 0.9rem;
}

.error {
  background-color: 'red';
}

.wrapChart {
  padding: 2rem;
}

.workingLeads {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 2rem;
  color: #999999;
  margin-right: 2rem;
}

.num {
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 700;
  line-height: 42px;
  color: #333333;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  border-top: #f0f0f0 1px solid;
}

.titleBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.title {
  color: #999999;
  line-height: 20px;
  padding-bottom: 24px;
}

.detailWrap {
  margin-top: 24px;
}

.tag {
  width: 44px;
  height: 24px;
  border-radius: 12px;
  background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
