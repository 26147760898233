@font-face {
  font-family: 'iconfont'; /* Project id 1861974 */
  src: url('iconfont.ttf?t=1718602818248') format('truetype');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.copy:before {
  content: '\e6c3';
}

.a-collect1:before {
  content: '\e6c2';
}

.location-event:before {
  content: '\e6c1';
}

.time-event:before {
  content: '\e6c0';
}

.quota-small:before {
  content: '\e6bf';
}

.quota:before {
  content: '\e6be';
}

.a-collect:before {
  content: '\e6bd';
}

.a-qrcode:before {
  content: '\e6bc';
}

.prudna:before {
  content: '\e6bb';
}

.notice:before {
  content: '\e6ba';
}

.a-eventdetaillocation:before {
  content: '\e6b9';
}

.a-myevents:before {
  content: '\e6b8';
}

.premium_due:before {
  content: '\e6b3';
}

.production:before {
  content: '\e6b4';
}

.policy_status:before {
  content: '\e6b5';
}

.persistency:before {
  content: '\e6b6';
}

.client:before {
  content: '\e6b7';
}

.submission:before {
  content: '\e6b2';
}

.Lock:before {
  content: '\e6b1';
}

.secretary:before {
  content: '\e6b0';
}

.tel1:before {
  content: '\e6af';
}

.a-SubscriptionList:before {
  content: '\e6ad';
}

.Vector:before {
  content: '\e6ac';
}

.Setting:before {
  content: '\e6ae';
}

.file_fail:before {
  content: '\e6a9';
}

.picture_fail:before {
  content: '\e6ab';
}

.language1:before {
  content: '\e6aa';
}

.refresh1:before {
  content: '\e6a8';
}

.Code:before {
  content: '\e6a5';
}

.Interview:before {
  content: '\e6a6';
}

.a-filefailed:before {
  content: '\e6a7';
}

.Preview-close:before {
  content: '\e6a3';
}

.Preview-open:before {
  content: '\e6a4';
}

.manager_view:before {
  content: '\e698';
}

.a-ManagerDashboard:before {
  content: '\e699';
}

.Points:before {
  content: '\e694';
}

.a-Scanout:before {
  content: '\e689';
}

.a-Scanin:before {
  content: '\e67b';
}

.Document:before {
  content: '\e676';
}

.tel:before {
  content: '\e677';
}

.money:before {
  content: '\e678';
}

.a-AttendanceScan:before {
  content: '\e674';
}

.Scan:before {
  content: '\e66f';
}

.a-TrainerAssistance:before {
  content: '\e673';
}

.a-Batchmanagement:before {
  content: '\e66e';
}

.Consent:before {
  content: '\e66d';
}

.record:before {
  content: '\e605';
}

.incentive:before {
  content: '\e603';
}

.license_benefits:before {
  content: '\e66a';
}

.underwrite:before {
  content: '\e66c';
}

.download:before {
  content: '\e66b';
}

.birthday:before {
  content: '\e669';
}

.preview:before {
  content: '\e668';
}

.export:before {
  content: '\e667';
}

.instruction_add_24:before {
  content: '\e663';
}

.arrow_right_24:before {
  content: '\e662';
}

.engagement_topic:before {
  content: '\e661';
}

.prospect_manage1:before {
  content: '\e65e';
}

.prospect_manage:before {
  content: '\e653';
}

.checkbox_checked:before {
  content: '\e64d';
}

.checkbox_unchecked:before {
  content: '\e64e';
}

.merge:before {
  content: '\e64c';
}

.edit1:before {
  content: '\e647';
}

.a-linechart:before {
  content: '\e646';
}

.a-barchart:before {
  content: '\e641';
}

.icon_customer:before {
  content: '\e63c';
}

.radio1:before {
  content: '\e637';
}

.toast_success:before {
  content: '\e636';
}

.toast_warning:before {
  content: '\e634';
}

.toast_error:before {
  content: '\e630';
}

.a-training_inprogress:before {
  content: '\e609';
}

.training_complete:before {
  content: '\e608';
}

.popup_mandatorytraining:before {
  content: '\e607';
}

.popup_traininghistory:before {
  content: '\e604';
}

.addcustomer:before {
  content: '\e6a2';
}

.whatsapp:before {
  content: '\e601';
}

.sort:before {
  content: '\e633';
}

.prusubmit:before {
  content: '\e631';
}

.GIEB:before {
  content: '\e632';
}

.calendar:before {
  content: '\e6a1';
}

.bianzu10:before {
  content: '\e62d';
}

.bianzu11:before {
  content: '\e62e';
}

.filteractive:before {
  content: '\e62f';
}

.send:before {
  content: '\e62c';
}

.comment:before {
  content: '\e629';
}

.fav:before {
  content: '\e62a';
}

.bookmark:before {
  content: '\e62b';
}

.news:before {
  content: '\e6a0';
}

.EPAcheckpayment:before {
  content: '\e618';
}

.EPAfps:before {
  content: '\e61b';
}

.EPAbank:before {
  content: '\e61c';
}

.EPAsavings:before {
  content: '\e61d';
}

.EPAtelextransfer:before {
  content: '\e627';
}

.EPApolicy:before {
  content: '\e628';
}

.tip_urgent:before {
  content: '\e602';
}

.report1:before {
  content: '\e69f';
}

.management:before {
  content: '\e69a';
}

.AMD:before {
  content: '\e69b';
}

.Claim:before {
  content: '\e69c';
}

.other:before {
  content: '\e69d';
}

.underwriting:before {
  content: '\e69e';
}

.stuff_login:before {
  content: '\e617';
}

.interview:before {
  content: '\e697';
}

.popup_search1:before {
  content: '\e696';
}

.popup_cource:before {
  content: '\e695';
}

.popup_expert:before {
  content: '\e693';
}

.book:before {
  content: '\e692';
}

.arrow_up:before {
  content: '\e691';
}

.chat:before {
  content: '\e690';
}

.message:before {
  content: '\e68f';
}

.popup_close:before {
  content: '\e68e';
}

.tabbar_saleskit_active:before {
  content: '\e68c';
}

.tabbar_saleskit_normal:before {
  content: '\e68d';
}

.instruction_info:before {
  content: '\e68b';
}

.runner:before {
  content: '\e68a';
}

.shortcut_card:before {
  content: '\e687';
}

.shortcut_lead:before {
  content: '\e688';
}

.shortcut_test:before {
  content: '\e686';
}

.detail:before {
  content: '\e685';
}

.hourglass:before {
  content: '\e684';
}

.instruction_add:before {
  content: '\e683';
}

.addmember:before {
  content: '\e681';
}

.phonebook:before {
  content: '\e682';
}

.qrcode:before {
  content: '\e680';
}

.shortcut_cpd:before {
  content: '\e67f';
}

.nav:before {
  content: '\e67e';
}

.shortcut_scan:before {
  content: '\e67d';
}

.shortcut_library:before {
  content: '\e67c';
}

.link:before {
  content: '\e679';
}

.contact1:before {
  content: '\e67a';
}

.photo:before {
  content: '\e675';
}

.contact:before {
  content: '\e672';
}

.edit:before {
  content: '\e671';
}

.report:before {
  content: '\e670';
}

.tabbar_recuit_normal1:before {
  content: '\e665';
}

.tabbar_recuit_active1:before {
  content: '\e666';
}

.tab_training_active:before {
  content: '\e664';
}

.info_locate:before {
  content: '\e660';
}

.info_time:before {
  content: '\e65f';
}

.tabbar_cal_normal:before {
  content: '\e60c';
}

.tabbar_cal_active:before {
  content: '\e610';
}

.tabbar_home_active:before {
  content: '\e612';
}

.tabbar_earning_active:before {
  content: '\e616';
}

.tabbar_earning_normal:before {
  content: '\e619';
}

.tabbar_home_normal:before {
  content: '\e61a';
}

.tabbar_me_normal:before {
  content: '\e620';
}

.tabbar_me_active:before {
  content: '\e621';
}

.tabbar_incentive_active:before {
  content: '\e622';
}

.tabbar_incentive_normal:before {
  content: '\e623';
}

.tabbar_policy_active:before {
  content: '\e624';
}

.tabbar_taining_normal:before {
  content: '\e625';
}

.tabbar_policy_normal:before {
  content: '\e626';
}

.notification:before {
  content: '\e65d';
}

.recruit_popup:before {
  content: '\e65c';
}

.leads_popup:before {
  content: '\e65b';
}

.train_popup:before {
  content: '\e65a';
}

.policy_popup:before {
  content: '\e659';
}

.per_popup:before {
  content: '\e658';
}

.system_popup:before {
  content: '\e657';
}

.scan:before {
  content: '\e656';
}

.filter:before {
  content: '\e655';
}

.more:before {
  content: '\e654';
}

.close:before {
  content: '\e652';
}

.arrow_back:before {
  content: '\e651';
}

.add:before {
  content: '\e650';
}

.refresh:before {
  content: '\e64f';
}

.bluetooth:before {
  content: '\e64b';
}

.headphone:before {
  content: '\e64a';
}

.video_selected:before {
  content: '\e649';
}

.video:before {
  content: '\e648';
}

.share:before {
  content: '\e645';
}

.reschedule:before {
  content: '\e644';
}

.mute_selected:before {
  content: '\e643';
}

.mute:before {
  content: '\e642';
}

.leave:before {
  content: '\e640';
}

.expansion:before {
  content: '\e63f';
}

.device:before {
  content: '\e63e';
}

.date:before {
  content: '\e63d';
}

.attendee:before {
  content: '\e63b';
}

.attend:before {
  content: '\e63a';
}

.popup_arrow:before {
  content: '\e639';
}

.menumore:before {
  content: '\e638';
}

.time:before {
  content: '\e635';
}

.done:before {
  content: '\e61f';
}

.language:before {
  content: '\e61e';
}

.girl:before {
  content: '\e615';
}

.boy:before {
  content: '\e614';
}

.arrow:before {
  content: '\e613';
}

.label_selected:before {
  content: '\e611';
}

.text_delete:before {
  content: '\e60f';
}

.radio_selected:before {
  content: '\e60e';
}

.radio:before {
  content: '\e60d';
}

.search:before {
  content: '\e60b';
}

.delete:before {
  content: '\e60a';
}

.arrow_down:before {
  content: '\e606';
}
