.wrap {
  border-radius: 5;
  margin: 1rem
}

.dateRange {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1rem;
  font-weight: normal;
}