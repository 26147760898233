@import "../variables.scss";

.svc-row {
  width: 100%;
}

.svc-row .sd-row--multiple {
  padding: calcSize(1);
  gap: calcSize(1);

  .sd-row--multiple {
    overflow: unset;
  }
}

.sd-panel .svc-row .sd-row--multiple {
  padding: calcSize(0);
  box-shadow: none;
  border-radius: 0;
  padding: 2px; //need for the https://github.com/surveyjs/survey-creator/issues/3288
  margin: -2px; //need for the https://github.com/surveyjs/survey-creator/issues/3288
}

.svc-row.svc-row--ghost {
  .svc-question__content {
    padding: 0;
    border: none;
    box-shadow: none;
  }

  .sd-row {
    height: calcSize(4);
  }

  &:last-child .sd-row {
    height: initial;
    margin-top: calcSize(2);
    margin-bottom: calcSize(2);
  }

  &+.svc-row {
    .sd-page__row {
      margin-top: 0;
    }
  }
}

.svc-panel .svc-row.svc-row--ghost.svc-row.svc-row--ghost {
  .sd-row {
    height: initial;
    margin-top: calcSize(2);
    margin-bottom: calcSize(2);
  }
}