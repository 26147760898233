#onboarding .sd-list__item.sd-list__item {
  min-height: 30px !important;
}

#onboarding .svc-page[data-sv-drop-target-page='termsNConditionsModule'] .svc-page__add-new-question,
#onboarding .svc-page[data-sv-drop-target-page='eSignatureModule'] .svc-page__add-new-question {
  display: none !important;
}

/*
#onboarding .svc-panel__add-new-question {
  display: none !important;
}

#onboarding .svc-panel__question-type-selector {
  display: none !important;
}

#onboarding .svc-designer-header {
  display: none !important;
} */

#onboarding .svc-designer-header {
  display: none !important;
}

#onboarding .svc-panel__add-new-question {
  display: block !important;
}

#onboarding .sd-btn--action {
  display: block !important;
  background-color: var(--primary, #19b394);
}

#onboarding .svc-tab-designer__page-navigator {
  padding-top: 16px;
}
/*
#onboarding .sd-element--complex.sd-question--complex.sd-question--table[data-name='validators'] #add-item,
#onboarding .sd-element--complex.sd-question--complex.sd-question--table[data-name='validators'] #remove-row,
#onboarding
  .sd-element--complex.sd-question--complex.sd-question--table[data-name='validators']
  .spg-btn.spg-matrixdynamic__add-btn.spg-action-button.spg-action-button--large {
  display: none !important;
}
#onboarding .svc-question__adorner[data-sv-drop-target-survey-element='termsNConditionsSection'] #delete,
#onboarding
  .svc-question__adorner[data-sv-drop-target-survey-element='termsNConditionsSection']
  .svc-panel__add-new-question {
  display: block !important;
}

#onboarding .svc-question__adorner[data-sv-drop-target-survey-element='documentUploadSection'] #delete,
#onboarding
  .svc-question__adorner[data-sv-drop-target-survey-element='documentUploadSection']
  .svc-panel__add-new-question {
  display: block !important;
}

#onboarding .svc-page-navigator__selector + div .svc-page-navigator-item:last-child {
  display: none;
}  */
