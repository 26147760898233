.wrap {
  display: flex;
  flex-direction: column;
  align-items: center
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: '#333'
}

.content {
  font-size: 14px;
  font-weight: 500;
  color: '#999'
}