.wrapChart {
  padding: 2rem;
  margin: 24px 0;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tag {
  width: 44px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
