.wrap {
  border-radius: 5;
  position: relative;
}

.title {
  font-family: SF Pro Text;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  margin-bottom: 2rem;
}

.icon {
  color: #ccc;
  padding-left: 0.2rem;
}

.error {
  background-color: 'red',
}

.dataStack {
  cursor: pointer;
}