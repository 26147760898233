.wrap {
  margin-bottom: 1rem;
}

.wrap h2 {
  font-size: 1.3rem;
  padding: 0.4rem 0;
}

.wrap span {
  font-size: 0.9rem;
  word-break: break-word;
  overflow-wrap: anywhere;
}

.icon {
  color: #ccc;
  padding-left: 0.2rem;
}
