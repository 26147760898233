.bar-container {
  flex-direction: column;
  display: flex;
  font-style: normal;
  color: #333333;
  border-bottom: 1px solid #f0f0f0;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.bar-chart-title {
  flex-direction: row;
  display: flex;
}

.bar-chart-life {
  border: 3px;
  border-color: #e8192c;
  background-color: #e8192c;
  border-style: solid;
  border-radius: 10px;
  height: 18px;
}

.bar-chart-medical {
  border: 3px;
  border-color: #38527e;
  background-color: #38527e;
  border-style: solid;
  border-radius: 10px;
  height: 18px;
}

.bar-chart-criticalillness {
  border: 3px;
  border-color: #009cbd;
  background-color: #009cbd;
  border-style: solid;
  border-radius: 10px;
  height: 18px;
}

.bar-chart-savings {
  border: 3px;
  border-color: #ffc959;
  background-color: #ffc959;
  border-style: solid;
  border-radius: 10px;
  height: 18px;
}

.bar-chart-others {
  border: 3px;
  border-color: #008a37;
  background-color: #008a37;
  border-style: solid;
  border-radius: 10px;
  height: 18px;
}

.bar-chart-span {
  margin-left: 14px;
}

.bar-chart-money {
  margin-top: 12px;
  flex-direction: row;
  display: flex;
  align-items: baseline;
}

.bar-chart-measurement {
  font-size: 12px;
  font-weight: 600;
}

.bar-chart-number {
  font-weight: 600;
  font-size: 18px;
  margin-inline: 5px;
}

.MuiTab-textColorInherit.Mui-selected {
  color: #e8192c;
  background: #ffffff !important;
  border-radius: 10px;
}

.commentary-table {
  flex-direction: column;
  display: flex;
  font-style: normal;
  color: #333333;
  border-bottom: 1px solid #f0f0f0;
  padding-inline: 20px;
  padding-block: 16px;
  background-color: #ffffff;
}

.comment-content {
  margin-top: 14px;
}

.pageBreak {
  page-break-after: always;
}
