#customer-survey .loading {
  margin: auto;
}

#customer-survey .loading-la-d1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#customer-survey .loading-cir-la {
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  border-top-color: #e8192c;
  animation: rotateloading 0.8s infinite linear;
}

@keyframes rotateloading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#customer-survey .loading-cir-la-content {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

#customer-survey body {
  font-family: 'Arial' !important;
  padding-bottom: 0;
}

#customer-survey .sd-title {
  font-family: 'Arial' !important;
  color: #333333 !important;
}

#customer-survey .sd-page__title {
  display: none !important;
}

#customer-survey .sd-title.sd-container-modern__title {
  gap: 0 !important;
  margin: 0 !important;
  padding: 0 16px !important;
  color: #333333 !important;

  box-shadow: none !important;
}

#customer-survey .sd-question__header h5 {
  color: #333333 !important;
  font-size: 14px !important;
  line-height: 28px !important;
}

#customer-survey .sd-header__text {
  gap: 0 !important;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: #f0f0f0 solid 1px !important;
}

#customer-survey .sd-header__text .sd-element--with-frame {
  border-radius: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

#customer-survey .sd-header__text h3 {
  color: #333333 !important;
  font-size: 20px !important;
  padding: 0 !important;
  font-weight: 600 !important;
  margin-bottom: 4px !important;
  line-height: 22px !important;
}

#customer-survey .sd-header__text h5 {
  font-family: 'Arial' !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #999999 !important;
}

#customer-survey .sd-question__header--location-top {
  display: table-header-group;
  margin-left: 16px;
  padding-bottom: 0 !important;
}

#customer-survey .sd-element__title span {
  font-size: 14px !important;
}

#customer-survey .sd-element__title .sd-element__num {
  color: #333333 !important;
}

#customer-survey .sd-radio__decorator {
  border: 1px solid #bababa;
}

#customer-survey .sd-radio--checked .sd-item__decorator {
  background: white !important;
  border: #e8192c solid 1.5px !important;
}

#customer-survey .sd-radio--checked .sd-radio__decorator:after {
  content: ' ';
  display: block;
  width: calc(1 * var(--base-unit, 8px));
  height: calc(1 * var(--base-unit, 8px));
  border-radius: 50%;
  background-color: #e8192c !important;
}

#customer-survey .sd-radio--checked .sd-radio__control:focus + .sd-radio__decorator:after {
  background-color: #e8192c !important;
}

#customer-survey .sd-row {
  margin-top: 0 !important;
  background-color: white;
}

#customer-survey .sd-page {
  padding: 0 !important;
  width: 100%;
  box-sizing: border-box;
}

#customer-survey .sd-body.sd-body--static .sd-body__timer,
#customer-survey .sd-body.sd-body--static .sd-body__navigation,
#customer-survey .sd-body.sd-body--static .sd-body__page {
  margin: 0 !important;
  min-width: 100% !important;
}

#customer-survey .svc-logic-question-value,
#customer-survey div:only-child > .sd-element--with-frame:not(.sd-element--collapsed) {
  border-radius: 0 !important;
}

#customer-survey .sd-body__navigation {
  padding: 0 16px 32px 16px !important;
}

#customer-survey .sv-action--hidden {
  display: none;
}

#customer-survey .sv-action {
  width: 100%;
}

#customer-survey .sd-btn {
  font-size: 16px !important;
  font-weight: 600 !important;
  background: #e8192c !important;
  border-radius: 40px !important;
  width: 100%;
  height: 48px;
  line-height: 10px !important;
  color: white !important;
  box-shadow: none !important;
}

#customer-survey .sd-navigation__prev-btn {
  color: #333333 !important;
  background: #ffffff !important;
  border: 1.5px #e6e6e6 solid !important;
}

#customer-survey .sd-body__navigation .sd-btn {
  padding: initial !important;
}

#customer-survey .sd-body__navigation .sv-action:not(.sv-action--hidden) ~ .sv-action:not(.sv-action--hidden) {
  margin-left: 11px !important;
}

#customer-survey .svc-logic-question-value,
div:only-child > .sd-element--with-frame:not(.sd-element--collapsed) {
  box-shadow: none !important;
}

#customer-survey .sd-root-modern {
  font-family: 'Arial' !important;
  background: white !important;
}

#customer-survey .sd-item__control-label {
  margin-left: 0 !important;
  display: flex;
}

#customer-survey .sd-item__control-label span {
  font-family: 'Arial' !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding-left: 16px !important;
}

#customer-survey .sd-item__control-label a {
  color: red !important;
}

#customer-survey .sd-checkbox__decorator {
  border: 1.5px solid #cccccc;
  border-radius: 2.25px !important;
}

#customer-survey .sd-checkbox--checked .sd-checkbox__svg use {
  fill: white !important;
}

#customer-survey .sd-checkbox--checked .sd-checkbox__decorator {
  background-color: #e8192c !important;
  border: 1px solid #e8192c !important;
  border-radius: 3px !important;
}

#customer-survey .sd-input {
  font-family: 'Arial' !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  border: none !important;
  border-bottom: #f0f0f0 solid 1px !important;
  box-shadow: none !important;
  background-color: white !important;
  padding: 0 !important;
}

#customer-survey .sd-text {
  background-color: white !important;
  border-bottom: #f0f0f0 solid 1px !important;
}

#customer-survey .sd-selectbase__label {
  align-items: center;
}

#customer-survey .sd-item--disabled.sd-item--disabled .sd-item__decorator,
#customer-survey .sd-item__decorator {
  width: 18px !important;
  height: 18px !important;
  /* background: white !important; */
  box-shadow: none !important;
  border: 1.5px #cccccc solid !important;
}

#customer-survey .sd-comment {
  overflow: hidden;
  min-height: 28px !important;
}

#customer-survey .sd-row__question {
  display: table;
}

#customer-survey .sd-question__description {
  margin: 12px 0;
}

#customer-survey .sd-question__erbox--above-question {
  display: table-row-group;
  background-color: white !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #e8192c !important;
}

#customer-survey .sd-imagepicker__item img {
  border-radius: 12px !important;
  width: 120px;
  height: 120px;
  object-fit: cover !important;
}

#customer-survey .sd-imagepicker__item--checked img {
  border: 2px solid #e8192c !important;
  object-fit: cover !important;
}

#customer-survey .sd-imagepicker__item:focus-within .sd-imagepicker__image,
#customer-survey .sd-imagepicker__item--allowhover .sd-imagepicker__image:hover {
  opacity: 1 !important;
}

#customer-survey .sd-imagepicker__item--checked .sd-imagepicker__image-container::before {
  top: auto !important;
  bottom: calc(1 * var(--base-unit, 1px)) !important;
  right: calc(1 * var(--base-unit, 1px)) !important;
  width: calc(4 * var(--base-unit, 8px)) !important;
  height: calc(4 * var(--base-unit, 8px)) !important;
  border-radius: 0 !important;
  background-color: #e8192c !important;
  border-top-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23' height='17' viewBox='0 0 23 17' fill='none'%3E%3Cpath d='M8.33331 16.1L0.333313 8L2.43331 5.9L8.33331 11.9L20.2333 0L22.3333 2.1L8.33331 16.1Z' fill='white'/%3E%3C/svg%3E") !important;
}

#customer-survey .sd-completedpage:before {
  height: 0 !important;
}

#customer-survey .sd-completedpage h3 {
  padding: 0 16px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 22px;
  color: #333333;
}

#customer-survey .saveErrorContainer {
  display: flex;
  justify-content: center;
}

#customer-survey .saveAgainBtn {
  margin: 0 32px;
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 40px;
  background-color: #e8192c;
  line-height: 24px;
  color: white;
  border: none;
  width: 350px;
}

#customer-survey .svc-logic-question-value,
#customer-survey .sd-element:not(.sd-element--collapsed) {
  padding-top: 0 !important;
}

#customer-survey .sd-rating__item {
  border-radius: calc(12.5 * var(--base-unit, 1px)) !important;
  border: 1px solid var(--background, #e6ebf0) !important;
  box-shadow: none !important;
  font-size: 14px;
}

#customer-survey .sd-rating__item--selected {
  background-color: #feeff0 !important;
  border-color: #ffc7ca !important;
  color: #ed1b2e !important;
  font-size: 14px;
}

#customer-survey .sd-rating__item:focus-within {
  border-color: #ffc7ca !important;
}

#customer-survey .sd-rating__item--allowhover {
  border-color: #e6ebf0 !important;
}

#customer-survey .sd-rating__item--selected:focus-within {
  box-shadow: none !important;
}

#customer-survey .sd-description {
  font-family: 'Arial' !important;
  font-weight: 400 !important;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  color: #999999 !important;
}

#customer-survey .sd-file__drag-area-placeholder {
  display: none !important;
}

#customer-survey .sd-file__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#customer-survey .sd-file__choose-btn--text {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin-top: 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 1px dashed #cccccc !important;
  border-radius: 12px !important;
  font-family: 'Arial' !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #333333 !important;
}

#customer-survey .sd-file {
  min-height: 43px !important;
}

#customer-survey .sd-file--single {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

#customer-survey .sd-file--single .sd-file__clean-btn {
  position: initial !important;
}

#customer-survey .sd-file--single-image {
  height: 100% !important;
}

#customer-survey .sd-file__decorator {
  border: none !important;
}

#customer-survey .sd-file__list {
  padding: 0 !important;
  position: inherit !important;
  max-height: none !important;
}

#customer-survey .sd-file__preview {
  min-height: 0 !important;
  width: 120px;
  height: 120px;
  margin: 0 !important;
  justify-content: center;
  border: 1px dashed #cccccc;
  border-radius: 12px;
}

#customer-survey .sd-file__image-wrapper {
  min-height: 0 !important;
}

#customer-survey .sd-file__default-image {
  width: 36px !important;
  height: 31px !important;
}

#customer-survey .sd-file__choose-btn--icon {
  top: auto !important;
  position: absolute;
  bottom: 0;
  left: 0;
}

#customer-survey .sd-file--single-image .sd-file__image-wrapper img {
  width: 120px !important;
  height: 120px !important;
  border-radius: 12px;
  object-fit: cover !important;
}

#customer-survey .sd-file__sign {
  margin-top: 0 !important;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #333333;
}

#customer-survey .sd-file__choose-btn {
  display: none;
}

#customer-survey .sd-file__clean-btn {
  right: auto !important;
  display: flex;
  margin-top: 12px;
  flex-direction: row-reverse;
  align-items: center;
}

#customer-survey .sd-file__clean-btn svg {
  width: 17px !important;
  height: 17px !important;
}

#customer-survey .sd-file__clean-btn span {
  display: flex !important;
  margin-left: 6px;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #e8192c;
}

#customer-survey .sd-context-btn {
  padding: 0 !important;
}

#customer-survey .sd-context-btn use {
  fill: #e8192c !important;
}

#customer-survey .sd-multipletext__item-container {
  flex-direction: column;
}

#customer-survey .sd-multipletext tr:not(:last-child) .sd-multipletext__cell {
  padding-bottom: 24px !important;
}

#customer-survey .sd-multipletext__item-title {
  margin-top: 0 !important;
  margin-bottom: 4px !important;
  font-style: normal;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #333333 !important;
  border-right: none !important;
}

#customer-survey .sd-multipletext__item input {
  margin-bottom: 7px !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  border: none !important;
}

#customer-survey .sd-element--with-frame .sv-ranking-item {
  left: 0 !important;
}

#customer-survey .sv-ranking-item__text {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

#customer-survey .sv-ranking-item__content {
  margin: 12px 0;
}

#customer-survey .sv-ranking-item__index {
  margin-right: 12px !important;
  background: #f5f5f5 !important;
  color: #999999 !important;
  border-radius: 25% !important;
  height: 24px !important;
  width: 24px !important;
}

#customer-survey .sd-imagepicker {
  padding-top: 10px !important;
}

#customer-survey .sd-imagepicker__text {
  display: none;
}

#customer-survey .sv-ranking-item__icon {
  margin-left: 0 !important;
  fill: gray !important;
  top: 10px !important;
}

#customer-survey .sd-dropdown {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.7802 1.46682L10.7197 0.406314L5.99998 5.12606L1.28023 0.406314L0.219727 1.46682L5.99998 7.24707L11.7802 1.46682Z' fill='%23333333'/%3E%3C/svg%3E%0A") !important;
  background-size: calc(3 * var(--base-unit, 4px)) calc(3 * var(--base-unit, 4px)) !important;
}

#customer-survey input::-webkit-input-placeholder {
  color: #999999 !important;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

#customer-survey textarea::-webkit-input-placeholder {
  color: #999999 !important;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

#customer-survey .tncRoot {
  display: table;
  padding-left: 16px !important;
}

#customer-survey .tncRoot .sd-question__erbox--above-question {
  display: table-footer-group;
}

#customer-survey .tnc .sv-string-viewer {
  padding-left: 10px !important;
}

#customer-survey .sv-title-actions__title span {
  max-width: none;
}

#customer-survey .sv-title-actions {
  display: inline !important;
}
